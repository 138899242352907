import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../yoti.css"
const YotiFailure = () => (
  <Layout>
    <SEO title="Yoti Verification Failed :(" />
    <div className="yotiFailure">
      <p>
        It is with great sadness I need to inform you that your Yoti session
        failed. Please get into contact with us and we will let you know how to
        proceed. Give any info on why you think it might have failed. Email us
        at <a href="mailto: hello@addfriends.com">hello@addfriends.com</a>
      </p>
      .
    </div>
  </Layout>
)

export default YotiFailure
